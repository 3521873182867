<template>
  <div class="footer-landing-flex-desktop footer-landing-mobile is-align-items-center">
    <div  class="footer-mb-25-mobile">
      <a class="text-nunito-light" style="font-size: 14px; color: #626262;" target="_blank" href="https://api.whatsapp.com/send?phone='+34643943764'&text=hola,%20me%20gustaria%20saber%20mas%20sobre%20Emitus">Contact us! +34 643943764</a>
    </div>
    <div class=" footer-mb-25-mobile">
      <a class="text-nunito" style="font-weight: bold; font-size: 14px; color: #626262;" href="/"> Emitus 2022®</a>
    </div>
    <div @click="scrollTop" class="text-nunito-light footer-pb-50-mobile">
      <!-- terms and conditions -->
      <router-link to="/terms" target="_blank" class="text-nunito-light" style="font-size: 14px; color: #626262;">Terms  of use </router-link>
      |
      <!-- privacy policies -->
      <router-link to="/privacy" target="_blank" class="text-nunito-light" style="font-size: 14px; color: #626262;">Privacy Policy </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'footerLanding',
  components: {},
  methods: {
    scrollTop () {
      window.scroll(0, 0)
    }
  }
}
</script>

<style>
@media (min-width: 768px) {
    .footer-landing-flex-desktop {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 93%;
        height: 100px;
    }
}

@media (max-width: 768px) {
    .footer-landing-mobile {
        width: 75%;
        height: 60px;
        margin: 53px auto 0 auto;

    }
    .footer-mb-25-mobile {
        margin-bottom: 25px;
    }
    .footer-pb-50-mobile {
        padding-bottom: 150px;
    }
}
</style>
