import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async newPrebooking (idToken, prebookingObjInit) {
    return await apiClient.post('/prebookings/', prebookingObjInit,
      {
        headers: { idToken: idToken }
      })
  },
  async getPrebooking (idToken, prebookingId) {
    return await apiClient.get(`prebookings/${prebookingId}`,
      {
        headers: { idToken: idToken }
      })
  },
  async updatePrebooking (idToken, prebookingId, prebookingObj) {
    return await apiClient.put(`prebookings/${prebookingId}`, prebookingObj,
      {
        headers: { idToken: idToken }
      })
  },
  async deletePrebooking (idToken, prebookingId) {
    return await apiClient.delete(`prebookings/${prebookingId}`, {},
      {
        headers: { idToken: idToken }
      })
  }

}
