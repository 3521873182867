<template>
  <div class="about-public">
    <!-- title -->
    <h2 class=" text-basier dark-blue-emitus">About me</h2>
    <!-- Description user -->
    <p class="paragraph-content dark-gray-emitus">
      {{description}}
    </p>
    <!-- skills user -->
    <div
      class="content-skills" id="content_skills" >
      <p
        class="text-basier dark-blue-emitus skill-public-tag"
        v-for="(item, index) in skills" :key="index">
        {{item}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'aboutPublic',
  components: {},
  props: {
    description: String,
    skills: Array
  }
}
</script>

<style scoped>
.content-skills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8%;
}

.content-skills p {
  background: rgba(66, 229, 214, 0.1);
  border-radius: 5px;
  padding: 2px 8px 2px 8px;
  font-size: 13px;
  color: rgba(13, 18, 57, 0.84)!important;
  margin: 6px 3px 1px 2px!important;
  text-align: center;
}

.about-public {
  width: 83%;
  max-width: 460px;
  margin: 0 auto;
}

.about-public h2 {
  font-size: 30px;
  line-height: 50px;
  color: #0d1239;
  margin: 80px auto 58px 0;
}

@media (min-width: 959px) {
  .about-public h2 {
    display: none;
  }

  .about-public {
    width: 100%;
    margin-top: 35px;
  }
}
</style>
