<template>
  <!-- navbar publicProfile if not authenticated -->
  <div>

    <div
      id="navbarPublicProfiledesktopAnScroll"
      class="nav-bar-public-profile-scroll is-hidden-touch">

      <div
        class="is-justify-content-space-between is-flex container-nav-bar-public-profile-scroll">
        <a
          href="/">
          <img
            v-if="location_1 !== '/'"
            src="@/assets/images/landing/emitus-logo-gray-2.svg"
            alt="logo emitus"
            class="logo-header-gray logo-header-desktop"
          >
        </a>
        <div
          class="is-flex is-align-items-center">
          <div
            class="mr-5 Basier-small-regular"
            style="color: #515679;">
            <!-- Login -->
            <!-- <a
              @click="clickShowContinue"
              class="is-size-small-text link-text-purple-no-decoration text-basier-medium">
              Log in
            </a> -->
          </div>
          <!-- <button
            @click="$router.push('/signup')"
            class="button-extra-small text-basier-medium">
            Signup
          </button> -->
        </div>
      </div>
    </div>

    <div
      class="nav-bar-first-screen-public is-justify-content-space-between px-3 is-hidden-desktop"
    >
      <a
        class="logo-header-gray"
        href="/">
        <img
            v-if="location_1 !== '/'"
            src="https://emituscdn.com/Logo_white_opacity.svg"
            alt="Go Back"
            class="logoImg"
            >

      </a>
      <div
        class="is-flex is-align-items-baseline"
        style="color: #fff;"
      >
        <div
          class="mr-3 Basier-small-regular"
          style="color: #fff;">
          <!-- Login -->
          <a
            @click="clickShowContinue"
            class="is-size-small-text text-basier-medium"
            style="color: #fff; text-decoration: none;">
            Log in
          </a>
        </div>
        |
        <button
          @click="$router.push('/signup')"
          class="btn_nav_public_mobile is-size-small-text text-basier-medium ml-3">
          Signup
        </button>
      </div>
    </div>
    <!-- Continue Modal -->
    <b-modal
        v-model="showContinue"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <Continue
            @close="showContinue = false"
          />
        </div>

    </b-modal>
  </div>
</template>

<script>
import Continue from '@/components/modals/continue.vue'
export default {
  components: { Continue },
  name: 'nav_bar_public',
  data () {
    return {
      showContinue: false,
      location_1: true,
      logoEmitus: true
    }
  },
  created () {
    window.addEventListener('scroll', this.scrollNavbarPublicProfile)
    this.notify.$on('hiddeModalContinue', data => {
      this.showContinue = data
    })
    this.location_1 = window.location.pathname
    console.log(this.location_1)
  },
  // mounted () {
  //   console.log(this.logoEmitus)
  //   this.notify.$on('hiddeLogoPublicProfile', () => {
  //     console.log('recibo algo?')
  //     this.logoEmitus = false
  //     console.log(this.logoEmitus)
  //   })
  // },
  methods: {
    scrollNavbarPublicProfile () {
      const resp = window.scrollY
      // console.log(resp)
      if (resp > 600) {
        document.getElementById('navbarPublicProfiledesktopAnScroll').classList.remove('is-hidden-touch')
      } else {
        document.getElementById('navbarPublicProfiledesktopAnScroll').classList.add('is-hidden-touch')
      }
    },
    clickShowContinue () {
      this.showContinue = true
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollNavbarPublicProfile)
    this.notify.$off('hiddeModalContinue')
  }

}
</script>

<style scoped>

.nav-bar-public-profile-scroll {
  z-index: 3;
  width: 100%;
  height: 67px;
  background-color: #fff;
  position: fixed;
  top: 0px;
  padding-top: 8px;
  border-bottom: 1px solid rgba(242, 242, 242, 0.7);
  display:none;
}

.container-nav-bar-public-profile-scroll {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  padding-top: 9px;
}

.nav-bar-first-screen-public {
  z-index: 3;
  width: 100%;
  height: 60px;
  background-color:
  transparent; position:
  absolute;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.logo-header-gray {
  width: 66.47px;
  height: 16.89px;
  margin-top: 5px;
  margin-left: 5px;
}
.link-login-header {
  font-size: 12.5px;
}

.btn_nav_public {
  width: 81px;
  height: 34px;
  background: #E6E7FD;
  border-radius: 14px;
  border: none;
  color: #6E3DEA;
  font-size: 12px;
  line-height: 12px;
}

.btn_nav_public_mobile {
  color: #fff;
  background: transparent;
  border: none;
}
.hidde-logo-emitus {
  display: none;
}

.logo-abogadus-nav-bar-me {
  width: 110px;
}

@media (min-width: 1024px) {
  .logo-header-desktop {
    width: 82px;
    height: 17.24px;
  }

  .button-extra-small {
    width: 97px;
  }
}
</style>
