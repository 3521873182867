<template>
  <div class="container-booking mt-6" >
    <div>
      <!-- component title -->
      <h3 class="text-basier has-text-left title-regular dark-blue-emitus mb-4">
        Duración
      </h3>
      <p class="description-nunito dark-gray-emitus has-text-left" style="font-size: 16px;">
        Selecciona la duración de la consulta ⏳
      </p>
    </div>
    <!-- session durations of the professional -->
    <div class=" is-flex is-flex-direction-column mt-6">
      <div
        v-for="(duration, pos) in durations"
        :key="pos">
        <button
          v-if="duration.minutes && duration.price"
          @click="durationSelected(duration)"
          class="mb-4"
          :class="duration.minutes === duration_ ? 'button-primary-extra-medium' : 'button-secundary-medium'"
        >
         <!-- <span class="text-basier-medium">{{duration.minutes}} min ({{duration.price}} {{currency === 'USD' ? '$' : '€'}})</span> -->
         <span class="text-basier-medium">{{duration.minutes}} min ({{duration.price}} {{currency === 'USD' ? '$' : '€'}})</span>
        </button>
      </div>
    </div >
  </div>
</template>

<script>
export default {
  name: 'durationBook',
  props: {
    currency: String,
    duration_: Number
  },
  data () {
    return {
      durations: []
      // currency: null
    }
  },
  created () {
    console.log(this.currency, '<<<<<<<')
    this.notify.$on('sendDurations', durations => {
      this.durations = durations
    })
    this.notify.$emit('giveMeDurations')
  },
  methods: {
    durationSelected (duration) {
      this.notify.$emit('durationSelected', duration)
    }
  }
  // methods: {
  //   clickDuration (durationEvent) {
  //     this.durationp = durationEvent
  //     // console.log(this.durationp, 'duracion! array?')
  //     this.currentStepsp = this.currentStepsp + 1
  //     this.$root.$emit('clickDurationHandler', this.durationp)
  //     this.$root.$emit('clickPagination', this.currentStepsp)
  //     this.updateBooking()
  //   },
  //   updateBooking () {
  //     this.$emit('updateBooking', {
  //       duration: this.durationp.minutes,
  //       price: this.durationp.price
  //     })
  //   }
  // }
}
</script>
