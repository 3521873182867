<template>
  <div class="container-booking mt-6">
    <div class="container-content-booking">
      <div>
        <!-- component title -->
        <p class="title-regular text-basier has-text-left title-regular dark-blue-emitus mb-4">
          Idioma
        </p>
        <p class="description-nunito dark-gray-emitus has-text-left" style="">
          Escoge el idioma en que prefieres la sesión.
        </p>
      </div>
      <div class="is-flex is-flex-direction-column mt-6">

        <!-- languages of the professional -->
        <b-button
          v-for="(language, pos) in languages"
          :key="pos + 'l'"
          class="mt-3"
          :class="language === language_ ? 'button-primary-medium' : 'button-secundary-small'"
          @click="languageSelected(language)">
            <span class="text-basier-medium">{{language}}</span>
        </b-button>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lenguagesBook',
  data () {
    return {
      languages: []
      // selected: false
    }
  },
  created () {
    this.notify.$on('sendLanguages', languages => {
      this.languages = languages
    })
    this.notify.$emit('giveMeLanguages')
  },
  props: {
    language_: String
  },
  methods: {
    languageSelected (language) {
      this.notify.$emit('languageSelected', language)
    }
  }
}
</script>

<style>
.button-primary-medium {}
</style>
