<template>

  <div>
      <!-- Si ya está logueado  -->
    <div v-if="authUser">
      <div class="header-booking mt-4">

        <!-- Close Modal -->
        <a @click="hiddeModal">
          <!-- icon back -->
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path d="M18 9.5H1" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.5 18L1 9.5L9.5 1" stroke="#919191" stroke-width="1.5"     stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>

        <!-- Nav steps in Modal -->
        <div>
          <a @click="prev">
            <!-- init svg icon preview-->
            <img id="icon_prev_book" :class="currentStep === 0 ? 'disabled-icon' : ''" src="@/assets/images/booking/pagination-prev.svg" alt="icon prev">
            <!-- fin svg icon preview-->
          </a>
          <a class="ml-2" @click="next">
            <!-- init svg icon next-->
            <img id="icon_next_book" :class="(
              currentStep === 3 ||
              ( currentStep === 0 && !language) ||
              ( currentStep === 1 && !duration) ||
              currentStep === 2
              ) ? 'disabled-icon' : ''" src="@/assets/images/booking/pagination-next.svg" alt="icon next">
            <!-- fin svg icon next-->
          </a>
        </div>

      </div>

        <!-- components booking -->
      <Language
        v-if="currentStep === 0 && !showAboutBuyer"
        :language_="language"
      />
      <Duration
        v-if="currentStep === 1 && !showAboutBuyer"
        :duration_="duration"
        :currency="currency"
      />
      <DateTime
        v-if="currentStep === 2 && !showAboutBuyer"
      />
      <Pay
        v-if="currentStep === 3 && !showAboutBuyer"
        :key="payKey"
      />
    </div>

    <!-- Si no esta logeado entonces muestra el join -->
    <div v-if="!authUser" class="content-modal-container">
      <Continuewoc  />
    </div>

    <!-- Si esta logeado pero le falta información -->
    <div v-if="showAboutBuyer" class="content-modal-container">
      <Aboutbuyer  />
    </div>

    <!-- Si el booking fue exitoso -->
    <div v-if="showSuccess">
      <Success :name="name" titleSuccess="¡Tu reserva ha sido enviada!"/>
    </div>

    <!-- Si el booking NO fue exitoso -->
    <div v-if="showError">
      <BookingError />
    </div>
  </div>

</template>

<script>
// Se necesitan
import firebase from 'firebase/app'
import 'firebase/auth'
import dayjs from 'dayjs'
import BookingService from '@/services/BookingService'
import ProfileService from '@/services/ProfileService'
import UserService from '@/services/UserService'
import MpService from '@/services/MpService'

import Continuewoc from '@/components/modals/continuewoc/'
import Aboutbuyer from '@/components/modals/aboutbuyer/'
import Success from '@/components/modals/booking_success'
import BookingError from '@/components/modals/booking_error'

import Language from '@/components/booking/language'
import Duration from '@/components/booking/duration'
import DateTime from '@/components/booking/dateTime'
import Pay from '@/components/booking/pay'

import mixpanel from 'mixpanel-browser'

export default {
  name: 'booking',
  components: {
    Continuewoc,
    Aboutbuyer,
    Success,
    Language,
    Duration,
    DateTime,
    Pay,
    BookingError
  },
  data () {
    return {
      // Needed info
      profileUsername: null,

      // Needed to bring data
      authUser: null,
      name: null,
      firstname: null,
      lastname: null,
      week: 1,
      avatar: null,
      user: null,

      // Data from DB
      languages: [],
      durations: [],

      // To send as booking request
      professionalId: '',
      startBook: new Date(),
      endBook: new Date(),
      duration: null,
      price: null,
      currency: null,
      language: null,
      mangopaywalletId: null,

      // Nav
      currentStep: 0,
      timeSelect: {}, // obj con duration + price selected desde el componente duration para pasarlo al componente dateTime
      showAboutBuyer: true,
      payKey: 0,

      // Success/Failure screens
      showSuccess: false,
      showError: false

    }
  },
  props: {
    professionalUsername: String
  },
  watch: {
    languages: function () {
      this.notify.$emit('sendLanguages', this.languages)
    },
    user: function () {
      this.showAboutBuyer = true
      if (!this.authUser) {
        this.showAboutBuyer = false
      } else if (
        (this.user.dateofbirth && typeof this.user.dateofbirth !== 'undefined') &&
        (this.user.countryorigin && typeof this.user.countryorigin !== 'undefined') &&
        (this.user.nationality && typeof this.user.nationality !== 'undefined')
      ) {
        this.showAboutBuyer = false
      }

      // get info about user
      this.firstname = this.user.firstname
      this.lastname = this.user.lastname
    },
    currentStep: function () {
      if (this.currentStep === 1) {
        mixpanel.track('Booking Languages')
      } else if (this.currentStep === 2) {
        this.notify.$emit('updateData')
        mixpanel.track('Booking Duration')
      } else if (this.currentStep === 3) {
        mixpanel.track('Booking Date&Time')
      }
    }
  },
  async created () {
    // Get profile ID
    if (this.$route.params.username === undefined) {
      this.profileUsername = this.professionalUsername
    } else {
      this.profileUsername = this.$route.params.username
    }

    // Get User fromm this.notify
    this.notify.$on('perro', (user) => {
      // console.log('996 user', user)
      this.user = user
      this.firstname = user.firstname
      this.lastname = user.lastname
    })
    // Get AuthUser fromm this.notify
    // this.notify.$on('updateAuthUser', (user) => {
    //   console.log('-------------->> 78', user)
    //   this.authUser = user
    // })

    this.showAboutBuyer = true
    if (!this.authUser) {
      this.showAboutBuyer = false
    } else if (
      (this.user.dateofbirth && typeof this.user.dateofbirth !== 'undefined') &&
        (this.user.countryorigin && typeof this.user.countryorigin !== 'undefined') &&
        (this.user.nationality && typeof this.user.nationality !== 'undefined')
    ) {
      this.showAboutBuyer = false
    }

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.authUser = user
        // Get Current User & Profile
        firebase.auth().currentUser.getIdToken(true)
          .then(idToken => {
            UserService.getCurrentUser(idToken)
              .then(user => {
                this.user = user.data
                this.firstname = user.firstname
                this.lastname = user.lastname
              })
          })
      }
    })

    // Receive request from componentes
    this.notify.$on('giveMeLanguages', () => {
      this.notify.$emit('sendLanguages', this.languages)
    })
    this.notify.$on('giveMeDurations', () => {
      this.notify.$emit('sendDurations', this.durations)
    })
    this.notify.$on('giveMeProfessionalId', () => {
      this.notify.$emit('sendProfessionalId', this.professionalId)
    })
    this.notify.$on('giveMeDuration', () => {
      this.notify.$emit('sendDuration', this.duration)
    })
    this.notify.$on('giveMePrice', () => {
      this.notify.$emit('sendPrice', this.price)
    })
    this.notify.$on('giveMeSummary', () => {
      this.notify.$emit('sendSummary', {
        avatar: this.avatar,
        name: this.name,
        firstname: this.firstname,
        lastname: this.lastname,
        minutes: this.duration,
        price: this.price,
        email: this.user.email,
        professionalId: this.professionalId,
        startBook: this.startBook,
        endBook: this.endBook,
        pending: this.user.mangopaywallet.pending,
        mangopaywalletId: this.mangopaywalletId,
        language: this.language
      })
    })

    // Get profile & user data
    await ProfileService.getPublicProfile(this.profileUsername)
      .then(profile => {
        this.languages = profile.data.profile.languages
        this.durations = profile.data.durations
        this.currency = profile.data.currency
        this.professionalId = profile.data._id
        this.name = profile.data.firstname + ' ' + profile.data.lastname
        this.avatar = profile.data.avatar
        this.mangopaywalletId = profile.data.mangopaywallet.id
        this.mangopaywalletCurrency = profile.data.mangopaywallet.currency
      })

    // Get Selected Language
    this.notify.$on('languageSelected', (language) => {
      this.currentStep = this.currentStep + 1
      this.language = language
    })

    // Get Selected Duration
    this.notify.$on('durationSelected', (duration) => {
      this.currentStep = this.currentStep + 1
      this.duration = duration.minutes
      this.price = duration.price
    })

    // Get Selected Date&Time
    this.notify.$on('slotSelected', (slot) => {
      this.currentStep = this.currentStep + 1
      this.startBook = slot.start
      this.endBook = slot.end
    })

    // Create booking request
    this.notify.$on('bookingRequest', async (obj) => {
      // const paymentAuth = false
      // if (paymentAuth) {
      this.makeBookingRequest(obj)
      // } else {
      //   console.log('--> Payment issue')
      // }
    })

    // Listen for Payment refresh
    this.notify.$on('refreshPayment', async () => {
      this.payKey++
    })

    // // Close AboutBuyer
    // this.notify.$on('closeAboutBuyer', () => {
    //   this.showAboutBuyer = false
    // })
  },
  metaInfo () {
    return {
      title: this.name + ' | emitus'
    }
  },

  // MOUNTED
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
    await this.notify.$emit('giveMeUserData')
  },

  // METHODS
  methods: {
    // Send request
    async makeBookingRequest (obj) {
      const timezone = dayjs.tz.guess()

      // Este objeto es el que hay que crear
      const bookingObj = {
        professional_id: this.professionalId,
        startBook: this.startBook,
        endBook: this.endBook,
        duration: this.duration,
        price: obj.price,
        currency: obj.currency,
        language: this.language,
        mppreauthid: obj.mppreauthid
      }
      // console.log(timezone)
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        BookingService.BookingRequest(idToken, timezone, bookingObj)
          .then(() => {
            this.showSuccess = true
          })
          .catch(error => {
            this.danger(error.response.data.msg)
            this.showError = true
          })
      } catch (error) {
        console.error('Conection Error', error.response.data.msg)
      }
    },

    // paginacion entre componentes de el booking
    next () {
      if (this.currentStep + 1 < 4) {
        this.currentStep = this.currentStep + 1
      }
    },
    prev () {
      if (this.currentStep - 1 >= 0) {
        this.currentStep = this.currentStep - 1
      }
    },

    // Close
    hiddeModal () {
      this.notify.$emit('close')
    },
    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  },
  beforeDestroy () {
    this.notify.$off('updateAuthUser')
    this.notify.$off('updateUser')
    this.notify.$off('giveMeLanguages')
    this.notify.$off('giveMeDurations')
    this.notify.$off('giveMeProfessionalId')
    this.notify.$off('giveMeDuration')
    this.notify.$off('giveMePrice')
    this.notify.$off('giveMeSummary')
    this.notify.$off('languageSelected')
    this.notify.$off('durationSelected')
    this.notify.$off('slotSelected')
    this.notify.$off('payNow')
    this.notify.$off('refreshPayment')
    this.notify.$off('bookingRequest')
  }

}
</script>

<style scoped>
.disabled-icon {
  opacity: 0.3!important;
  cursor: not-allowed!important;
}
.modal-container {
  width: 100%;
  background-color: #fff;
  height:100vh;
  position: fixed;
  z-index: 6;
  overflow: auto;
}

.general-container {
  width: 100%;
}

.image-user img {
  object-fit: cover;
  width: 59px;
  height: 59px;
  border-radius: 50%;
}

.text-white {
  color: #fff;
  font-size: 14px;
}
.container-content-entry {
  max-width: 253px;
  margin: 80px auto 187px auto;
  text-align: center;
}

.header-booking {
  width: 85%;
  max-width: 450px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 959px) {

  .content-modal-container {
    margin-top: 16vh;
  }

  .image-user img {
    width: 83px;
    height: 83px;
  }

  .container-content-entry {
    margin: 110px auto 187px auto;
  }

  .text-white {
    padding-top: 10px;
  }

  .modal-container {
    width: 40%;
    max-width: 504px;
    height:100vh;
    position: fixed;
    z-index: 6;
    top: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
}
.content-profesional {
  width: 80%;
  margin: 94px auto 103px auto;
}
.content-profesional h2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 50px;
  color: #0D1239;
}
.content-profesional h2:last-of-type {
  margin-top: 88px;
}
.content-profesional p {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #393939;
  margin-top: 31px;
}
.lenguage-location p {
  font-weight: normal;
  font-size: 12px;
  line-height: 36px;
  color: #FFFFFF;
}

  /* nav bar public */
.block_btn:hover {
  color: #929292!important;
  cursor: not-allowed;
  border-bottom: none!important;
}

.w-75 {
    width: 65px;
}
.nav-pubic {
    position: fixed;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 47px;
    background: #fff;
    border-bottom: 1px solid #F0F0F0;
}
.nav-pubic ul {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;
}
.nav-pubic ul a {
    color: #929292;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-pubic ul a:hover {
    color: #0D1239;
    border-bottom: 1px solid #0D1239;
}
.nav-pubic ul a:last-of-type {
    width: 30px;
}
.nav-pubic ul a:last-of-type:hover {
    background: #fff;
    border-bottom: 1px solid #F0F0F0;
}
.select-a {
    color: #0D1239!important;
    border-bottom: 1px solid #0D1239!important;
}
.disp-none {
    display: none;
}
.btn-connect {
  background: transparent!important;
}
.btn-connect a {
  width: 144px!important;
  margin: 0 25px 0 auto!important;
  box-shadow: 0px 7px 14px rgba(13, 18, 57, 0.29);
  border-radius: 18px;
}
.btn_info {
  z-index: 5;
  position: absolute;
  bottom: 148px;
  right: 19px;
  width: 86px;
  height: 44px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 18px;
  background: transparent;
  color: #FFFFFF;
}
.value-rate {
  width: 35px;
  background: transparent;
  border: none;
  outline: none;
  color: #fff!important;
}
.control .minus {
  display: none!important;
}
.control .plus {
  display: none!important;
}
.progress-wrapper .progress {
  height: 3px!important;
}

.container-tag-line {
  text-align: left;
  position: absolute;
  bottom: 99px;
}
/* prueba */
.filter-front {
  width: 100%;
  height: 100vh;
  z-index: 5;
  position: absolute;
  top: 0px;
  opacity: 0;
  background-color: #0d123933;
}
.invisible-front {
  width: 100%;
  height: 100vh;
  z-index: 5;
  top: 0px;
  opacity: 0;
  background-color: #0d123900;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: -75px;
}

.is-horizontal-center {
  justify-content: center;
}
.container-btn-Join-emitus {
  position: fixed;
  z-index: 5;
  bottom: 0;
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(13, 18, 57, 0.66);
}
.container-btn-Join-emitus  a {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-Join-emitus:hover, .btn-Join-emitus:focus, .btn-Join-emitus:active{
  background: #32af5c!important;
}
@media only screen and (min-width: 1024px) {

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 540px;
    margin-top: 0px;
  }
  .is-horizontal-center {
    justify-content: center;
  }
  .hero-body {
    padding: 0;
  }

  .hero.is-fullheight {
    background-color: #0d1239;
  }
  .control {
    max-width: 100%;
  }
  .filter-front {
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: absolute;
    top: 0;
    opacity: 1;
    background-color: transparent;
    border-radius: 0px;
    overflow: hidden;
  }

  .invisible-front {
    width: 100%;
    height: 100vh;
    z-index: 5;
    top: 0px;
    opacity: 1;
    background-color: transparent;
  }

}

@media (min-width: 1024px) {
  .container_avatar_public {
    height: 100%;
  }
  .img_public_avatar {
    height: 100%;
  }

  .title-bold {
    font-weight: 400;
    font-size: 30px;
    color: #0D1239;
  }
  .container-btn-Join-emitus-desktop {
    width: 90%;
    margin: 60px auto 0 auto;
  }
  .container-btn-Join-emitus-desktop a {
    padding: 17px 40px;
  }
  .btn-Join-emitus {
    box-shadow: none;
    background-color: #0dc366;
  }
  .container-tag-line-2 {
    width: 90%;
    margin: 0 auto;
  }
  .container-tag-line-2 p {
    color: #8E8E8E;
    margin-top: 0;
    font-size: 20px;

  }
  .value-rate {
    color:#0D1239!important;
  }
  .nav-pubic {
    display: block!important;
    border-bottom: none;
    left: 0;
    top: 168px;
    width: 20%;

  }
  .nav-pubic ul {
    flex-direction: column;
  }
  .nav-pubic ul a {
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .select-a {
    border-bottom: none!important;
  }
  .container_content_public {
    width: 50%;
    padding-left: 35px;
  }
  .container_desktop_content {
    display: flex;
    width: 60%;
    margin: 0 auto;
    justify-content: center;
    gap: 15px;
    padding-top: 120px;
  }
  .container-tag-line {
    display: none!important;
  }
  .hidde-join-mobile {
    position: fixed!important;
    top: 0px!important;
  }
  .margin_join_fixed {
    margin: 0 0 0 auto!important;
  }
  .container-btn-Join-emitus  {
    display: none!important;
  }

  .join-landing {
    width: 90% !important;
    margin: 140px auto 0 auto;
    top: 0px;
    display: block !important;
  }
  .emitus-video-landing {
    width: 297px;
    height: 524.02px!important;
    border-radius: 5px;
    overflow: hidden;
  }
  .emitus-video-landing video {
    width: 297px;
    height: 524.02px!important;
    border-radius: 5px;
    object-fit: cover;
  }
  .social {
    display: flex;
    height: 540px;
    width: 25px!important;
    order: -1;
    align-items: flex-end;
  }
  .container_social {
    flex-direction: column;
    margin-bottom: 0!important;
  }
  .container_social a {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.emitus-video-landing video {
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}
.filter-video-landing {
  position: absolute;
  top: 0;
}
.content-filter {
  color: #ffffff;
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  width: 100%;

}
.content-video-center {
  width: 90%;
}

.content-video-center h4 {
  margin-top: 100px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  width: 367px;
}
.content-video-center p {
  font: "Basier Circle Medium";
  max-width: 210px;
}
.container_social {
  width: 100%;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-evenly;
}
.container_social a {
  cursor: pointer;
}
.container_social a img{
  width: 15px!important;
  height: 15px!important;
}
@media (max-width: 1024px) {
    .logo-emitus-public-profile {
      width: 75px;
    }
    .hidde-join-mobile {
    display: none!important;

  }
  .social {
    margin-top: 65px;
  }
}
@media (max-width: 1024px) {
  .container_avatar_public {
    height: 100vh;
    width: 100vw;
  }
  .img_public_avatar {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }
  .join-position-mobile {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
  }

    .filter-video-landing {
    height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(
      180.81deg,
      rgba(13, 18, 57, 0.136) 0.66%,
      rgba(13, 18, 57, 0.704) 74.9%,
      rgba(13, 18, 57, 0.8) 99.3%
    );
    z-index: 2;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .filter-invisible {
    height: 100vh;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
  }

  .content-video-center h4 {
    margin-top: 90px;
    font-weight: 500;
    font-size: 40px;
    line-height: 45px;
    text-align: left;
    width: 271px;
    margin-bottom: 12px;
  }

}

/* ---DESKTOP--- */

@media (min-width: 1024px) {
  .content-profesional {
    width: 45%;
    padding: 0px 25px 0 25px;
    margin-top: 140px;
  }
  .content-profesional h2 {
    font-size: 20px;
  }
  .content-profesional h2:last-of-type {
    margin-top: 65px;
  }
  .logo-emitus-public-profile {
    margin-left: 5%;
  }
  .filter-video-landing {
    width: 297px;
    height: 524.02px!important;
    border-radius: 25.6034px;
    background: transparent;
    z-index: 2;

  }
  .filter-invisible {
    height: 100%;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  .video-height-video-desktop {
    height: 100vh;
  }
}
@media (min-width: 1025px) {
  .visible-desktop {
    display: block;
  }
}
</style>
