<template>
  <div class="not-bg-found not-h-100vh error">
    <div class="m-center">
      <div class="is-hidden-desktop" style="text-align: center;">
        <img style="max-height: 211px;" src="@/assets/images/booking/img-not-booking-error-mobile.svg" alt="imagen-success">
      </div>

      <div class="is-flex is-justify-content-center-mobile is-align-items-center">
        <div class=" is-hidden-touch">
          <img style="height: 100vh;" src="@/assets/images/booking/img-not-booking-error.svg" alt="imagen-success">
        </div>
        <div class="container-notBooking-error-desk">
          <div class="container is-max-desktop not-w-content pb-2">
            <h3 class="is-hidden-desktop has-text-white-ter text-primary mt-3 pt-3 is-size-4 has-text-weight-normal has-text-centered text-basier">Something wet wrong</h3>
            <h3 class="is-hidden-touch has-text-white-ter text-primary mt-3 pt-3 is-size-4 has-text-weight-normal has-text-centered text-basier">We’ve received your payment</h3>
            <p class="is-hidden-desktop not-w-content has-text-grey-lighter mt-4 pt-3 text-secondary is-size-7 has-text-weight-light has-text-centered text-nunito line-h-content">
                Please update your payment information
            </p>
            <p class="is-hidden-touch not-w-content has-text-grey-lighter mt-4 pt-3 text-secondary is-size-7 has-text-weight-light has-text-centered text-nunito line-h-content">
                We’ll sent you a confirmation email with the videocall link. Please check your inbox.
            </p>
          </div>

          <div class="buttons is-flex is-flex-direction-column not-w-btn container is-max-desktop mt-">
            <b-button @click="finishBooking()" class="text-basier is-rounded is-size-6 has-text-weight-bold background:linear-gradient(90deg, #6E3DEA -0.02%, #9A55F2 99.98%)" type="is-primary" expanded size="is-medium">Accept</b-button>
          </div >
          <div class="is-max-desktop container has-text-centered mt-5 pb-6">
            <a class="is-size-7 text-nunito">Need help? Please let us know</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BookingError',
  methods: {
    finishBooking () {
      this.notify.$emit('close')
    }
  }
}
</script>

<style scoped>
.error {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9;
}
.not-bg-found {
    background-color: #0d1239;
}
.not-w-content {
    width: 250px;
}
.not-w-btn {
    width: 210px;
}
.container-notBooking-error-desk p {
  width: 75%;
  margin: 0 auto;
}
.container-notBooking-error-desk h3 {
  width: 75%;
  margin: 0 auto;
}
.not-h-100vh {
    height: 100vh;
}
@media (min-width: 1024px) {
  .container-notBooking-error-desk {
    width: 50%;
    /* margin-top: 100px; */
  }
  .container-notBooking-error-desk h3 {
    margin-bottom: 35px;
  }

  .container-notBooking-error-desk .buttons {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .is-justify-content-center-mobile {
    justify-content: center;
  }
  .container-notBooking-error-desk .buttons {
    margin-top: 20px;
  }
}
</style>
