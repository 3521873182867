<template>
  <div id="pay_" class="container-booking pb-6 mt-4">
    <div class="mt-5">
      <h3 class="title-regular text-basier">4. Pago</h3>

      <!-- Purchase info -->
      <div
        class="is-flex mt-5 is-justify-content-space-between py-4"
        style="border-top: 1px solid #d4d4d4; border-bottom: 1px solid #d4d4d4"
      >
        <div class="is-flex">
          <div class="mr-4">
            <figure class="image m-center" style="width: 40px; height: 40px">
              <img class="img-radius-intro" :src="avatar" />
            </figure>
          </div>
          <div class="mr-4">
            <div class="field pb-1">
              <p class="text-basier-medium dark-blue-emitus">
                Consulta con
              </p>
            </div>
            <div class="field pb-4">
              <p
                class="text-nunito is-pulled-left"
                style="color: #000000; font-size: 14px; font-weight: 300"
              >
                {{ name }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="ml-4 is-flex is-flex-direction-column is-align-items-flex-end"
        >
          <div>
            <p
              class="text-nunito is-pulled-left"
              style="color: #000000; font-weight: 600; font-size: 17px"
            >
              {{ price }}€
            </p>
          </div>
          <div>
            <p
              class="text-nunito is-pulled-left"
              style="color: #6b6c7d; font-size: 12px; font-weight: 300"
            >
              <img src="@/assets/images/booking/watch.svg" alt="time" />
              {{ minutes }} min
            </p>
          </div>
        </div>
      </div>

      <!-- Name -->

      <div class="name-user is-flex mt-5">
          <!-- Firstname -->
          <b-field
            label="Nombre"
          >
            <b-input
              id="firstname"
              v-model="firstname"
              autocomplete="off"
              disabled
            >
            </b-input>
          </b-field>

          <!-- Lastname -->
          <b-field
            class="ml-2"
            label="Apellidos"
          >
            <b-input
              id="lastname"
              v-model="lastname"
              autocomplete="off"
              disabled
            >
            </b-input>
          </b-field>
        </div>

      <!-- SAVED CARD -->
      <div class="field mt-5" v-if="haveCards">

        <!-- Pay with Credits -->
        <b-field>
          <b-radio
            v-model="radio"
            name="name"
            native-value="myBalance">
            Pagar con saldo (Disponible: {{available.toFixed(2)}}€)
          </b-radio>
        </b-field>

        <!-- Pay with saved Cards -->
        <b-field
          v-for="(card, idx) in myCards"
          :key="idx"
          class="mt-4 pt-2">
            <b-radio
              type="radio"
              v-model="radio"
              name="name"
              :native-value="card.Id"
              class="mr-3 is-flex">
              <div>
                <div class="is-flex is-align-items-center">
                  <img
                    src="@/assets/images/panel/me/mastercard.svg"
                    alt="">
                  <span
                    class="text-nunito-Regular ml-4">
                    {{card.Alias.replaceAll('X', '*').slice(8)}}
                  </span>
                </div>
                <div>
                  <p
                    class="mt-2 text-nunito expired-text">
                    EXPIRA EN                   </p>
                </div>
              </div>
            </b-radio>
            <div class="is-flex">
              <div>
                <p
                  class="text-nunito-Regular">
                  {{card.ExpirationDate.slice(0, -2)}}/{{card.ExpirationDate.slice(-2)}}
                </p>
              </div>
              <div class="is-flex">
                <a class="btnDelete text-basier link-text-purple" @click="deleteCard(card.Id)">Eliminar</a>
              </div>
            </div>
        </b-field>

        <!-- Pay with NEW CARD -->
        <b-field class="mt-5">
          <b-radio
            v-model="radio"
            name="name"
            native-value="newCard"
            @click="clickFormNewCard">
            Pagar con tarjeta nueva

          </b-radio>
        </b-field>

        <!-- Pay with new Card -->
        <!-- <div v-if="linkNewCard" class="container-edit-bank mt-5">
          <a @click="clickFormNewCard" class="purple-dark-emitus text-basier" >+ Add new card</a>
        </div> -->
        <!-- <div
          v-if="linkNewCard"
          class="container-edit-bank mt-5 pt-2">
          <a
            @click="clickFormNewCard"
            class="ml-5 purple-dark-emitus text-basier link-text-purple">
            + Add new card
          </a>
        </div> -->

        <!-- C2A Saved CARD-->
        <div class="is-align-items-center
            is-flex">
        <b-button
          v-if="!showNewCard && !showMyBalance"
          @click="PreAuthSavedCard"
          class="
            is-margin-auto
            mt-6
            button-primary-extra-big
          "
          type="">
          Pagar ahora
        </b-button>
        </div>

        <!-- C2A My Balance-->
        <div class="is-align-items-center is-flex">
          <b-button
            v-if="showMyBalance"
            @click="payInWithBalance"
            class="
              button-primary-extra-big
              is-margin-auto
              mt-6
            "
            type="">
            Pagar ahora
          </b-button>
        </div>
      </div>

      <!-- NEW Credit Card -->
      <div v-if="showNewCard" class="field mt-5">
        <label class="label Basier-small-regular" style="color: #929292"
          >Tarjeta</label
        >

        <div class="control has-icons-left has-icons-right">
          <input
            v-model="mp_cardNumber"
            class="input text-nunito"
            type=""
            placeholder="Número de tarjeta" />
          <span class="icon is-small is-right text-nunito">
            <img src="@/assets/images/booking/creditCard.svg" alt="" />
          </span>
          <div class="is-flex">
            <input
              v-mask = "'##/##'"
              v-model="mp_cardExpirationDate"
              class="input text-nunito"
              type=""
              placeholder="MM/ AA" />
            <input
              v-model="mp_cardCvx"
              class="input text-nunito"
              type=""
              placeholder="CVV" />
          </div>
        </div>

        <div
          class="is-flex is-flex-direction-column is-align-items-center"
          style="margin-top: 21px; margin-bottom: 40px"
        >
          <label class="checkbox is-flex ml-2 is-align-items-center">
            <!-- <input id="checkbox" v-model="saveCard" type="checkbox" checked/> -->
            <p class="is-size-7 ml-3 text-nunito is-size-7">
              Tu tarjeta quedará guardada para futuras reservas y posibles devoluciones. Podrás eliminarla en cualquier momento.
            </p>
          </label>
        </div>

        <!-- C2A -->
        <div
          class="is-align-items-center
              is-flex">
          <b-button
            @click="payNowWithNewCard"
            class="
             button-primary-extra-big
              is-margin-auto
            "
            type="">
            Pagar ahora
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="loader">
      <Loading/>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import UserService from '@/services/UserService'
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'
import dayjs from 'dayjs'
import BookingService from '@/services/BookingService'
import PrebookingService from '@/services/PrebookingService'
import ProfileService from '@/services/ProfileService'
import { mask } from 'vue-the-mask'

export default {
  components: {
    Loading
  },
  name: 'paymenWall',
  directives: { mask },
  data () {
    return {
      user: null,
      avatar: null,
      firstname: null,
      lastname: null,
      name: null,
      email: null,
      minutes: null,
      price: null,
      currency: 'EUR',
      mp_UserId: null,
      mp_Currency: null,
      mp_accessKeyRef: null,
      mp_data: null,
      mp_cardNumber: null,
      mp_cardExpirationDate: null,
      mp_cardCvx: null,
      CardId: null,
      WalletId: null,
      CardReg_Id: null,
      saveCard: true,
      showNewCard: false,
      showMyBalance: false,
      myCards: null,
      radio: null,
      haveCards: false,
      linkNewCard: true,
      professionalId: '',
      startBook: new Date(),
      endBook: new Date(),

      cardAlias: null,
      balance: null,
      pending: null,
      loader: false,
      mangopaywalletId: null,

      prebookingId: null,
      language: null,

      baseURL: null
    }
  },
  async created () {
    this.notify.$on('sendSummary', summary => {
      this.avatar = summary.avatar
      this.firstname = summary.firstname
      this.lastname = summary.lastname
      this.minutes = summary.minutes
      this.price = summary.price
      this.email = summary.email
      this.name = summary.name
      this.professionalId = summary.professionalId
      this.startBook = summary.startBook
      this.endBook = summary.endBook
      this.pending = summary.pending
      this.language = summary.language
    })
    this.notify.$emit('giveMeSummary')
    // await ProfileService.getPublicProfile(this.$route.params.username)
    //   .then(profile => {
    //     this.languages = profile.data.profile.languages
    //     this.durations = profile.data.durations
    //     this.currency = profile.data.currency
    //     this.professionalId = profile.data._id
    //     this.name = profile.data.firstname + ' ' + profile.data.lastname
    //     this.avatar = profile.data.avatar
    //     this.mangopaywalletId = profile.data.mangopaywallet.id
    //     this.mangopaywalletCurrency = profile.data.mangopaywallet.currency
    //   })
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.authUser = user
        // Get Current User & Profile
        firebase.auth().currentUser.getIdToken(true)
          .then(idToken => {
            UserService.getCurrentUser(idToken)
              .then(user => {
                this.user = user.data
                this.pending = this.user.mangopaywallet.pending
                // console.log(this.pending)
              })
          })
      }
    })
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const myCardsResponse = await MpService.getMyCards(idToken)
    const myCardsData = myCardsResponse.data
    const myCards = myCardsData.filter(e => e.Active === true)
    this.myCards = myCards

    await MpService.getMyWallet(idToken)
      .then(wallet => {
        if (wallet.data.Balance.Amount && wallet.data.Balance.Amount !== 'undefined') {
          this.balance = wallet.data.Balance.Amount
        }
      })
      .catch(error => {
        this.danger(error.response.data.msg)
      })
  },

  // MOUNTED
  mounted () {
    if ((this.myCards === null || this.myCards.length === 0) && this.balance === null) {
      this.radio = 'newCard'
      this.haveCards = false
    } else {
      this.haveCards = true
    }
  },

  // WATCH
  watch: {
    radio: function () {
      if (this.radio === 'newCard') {
        this.showNewCard = true
        this.showMyBalance = false
      } else if (this.radio === 'myBalance') {
        this.showMyBalance = true
        this.showNewCard = false
      } else {
        this.showNewCard = false
        this.showMyBalance = false
      }
    },

    myCards: function () {
      if ((this.myCards === null || this.myCards.length === 0) && this.balance === null) {
        this.radio = 'newCard'
        this.haveCards = false
      } else {
        this.haveCards = true
      }
    }
  },

  // COMPUTED
  computed: {

    // Availabla Balance
    available: function () {
      return (this.balance / 100) - this.pending
    }
  },

  // METHODS
  methods: {
    async payNowWithNewCard () {
      if (this.radio) {
        const noOverlaps = await this.checkOverlaps()
        if (noOverlaps) {
          console.log('paso 1')
          this.loader = true
          // Create Card
          const CardRegistration = await this.createCardRegistrationObject() // Register object for card
          if (CardRegistration) {
            console.log('4400', this.baseURL)
            const RegistrationData = await this.postCardInfo(this.baseURL) // Tokenize Card info in 3rd party server
            console.log('334455', RegistrationData)
            if (RegistrationData) {
              console.log('paso 3')
              const registeredCard = await this.putTokenData(RegistrationData.data) // Complete de card registration
              console.log('99999', registeredCard)
              this.CardId = registeredCard.data.CardId
              // Create PreAuth
              if (this.CardId) {
                console.log('paso 4')
                const response = await this.PreAuth()
                const SecureModeNeeded = response.data.SecureModeNeeded
                const SecureModeRedirectURL = response.data.SecureModeRedirectURL

                // A) IF SUCCEEDED
                if (response.data.Status === 'SUCCEEDED') { // It worked
                  const obj = {
                    price: (response.data.DebitedFunds.Amount / 100),
                    currency: response.data.DebitedFunds.Currency,
                    mppreauthid: response.data.Id
                  }
                  this.bookingRequest(obj)
                  console.log('paso 5')

                  // B) IF FAILED
                } else if (response.data.Status === 'FAILED') { // It didn't work
                  this.danger('Pago Fallido')
                  this.loader = false

                  // C) IF WAITING FOR BANK VALIDATION 3DS
                } else if (SecureModeNeeded) {
                  const prebookingObj = {
                    price: (response.data.DebitedFunds.Amount / 100),
                    currency: response.data.DebitedFunds.Currency,
                    mppreauthid: response.data.Id
                  }
                  const idToken = await firebase.auth().currentUser.getIdToken(true)
                  const prebookingRes = await PrebookingService.updatePrebooking(idToken, this.prebookingId, prebookingObj)
                  if (prebookingRes.status === 200) {
                    window.open(SecureModeRedirectURL, '_self')
                    this.loader = false
                    console.log('paso 6')
                  } else {
                    this.danger('Hubo un problema con el pago')
                    this.loader = false
                  }
                }
              } else {
                this.danger('Por favor elige otra fecha')
                this.loader = false
              }
            }
          }
        } else {
          this.danger('Por favor elige otra fecha')
          this.loader = false
        }
      } else {
        this.danger('Por favor elige método de pago')
        this.loader = false
      }
    },

    // Create a new card registration object
    async createCardRegistrationObject () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      try {
        const response = await MpService.createCardRegistrationObject(idToken)
        // console.log('---------------------------------------->> 15', response)
        this.mp_accessKeyRef = response.data.AccessKey
        this.mp_data = response.data.PreregistrationData
        this.WalletId = response.data.WalletId
        this.mp_UserId = response.data.MpUserId
        this.mp_Currency = response.data.MpCurrency
        this.CardReg_Id = response.data.Id
        this.baseURL = response.data.CardRegistrationURL
        return true
      } catch (error) {
        this.danger(error)
        return false
      }
    },

    // Post Card Info
    async postCardInfo (baseURL) {
      const obj = {}
      obj.accessKeyRef = this.mp_accessKeyRef
      obj.data = this.mp_data
      // obj.returnURL = 'https://emitus-panel.vercel.app/panel/booking'
      obj.cardNumber = this.mp_cardNumber
      // console.log('6666', this.mp_cardExpirationDate)
      const fixedExpDate = this.mp_cardExpirationDate.replace('/', '')
      // console.log('7777', fixedExpDate)
      obj.cardExpirationDate = fixedExpDate
      obj.cardCvx = this.mp_cardCvx

      try {
        const response = await MpService.postCardInfo(obj, baseURL)
        return response
      } catch (error) {
        this.danger(error)
        return false
      }
    },

    // Put Token Data
    async putTokenData (RegistrationData) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const obj = {}
      obj.RegistrationData = RegistrationData
      const response = await MpService.putTokenData(idToken, obj, this.CardReg_Id)
      return response
    },

    // PreAuth with NEW Card
    async PreAuth () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await UserService.getCurrentUser(idToken)
      this.user = response.data
      const now = new Date()
      const request = new XMLHttpRequest()
      request.open('GET', document.location, false)
      request.send(null)
      const headers = request.getResponseHeader('content-type').toLowerCase()
      const myIpAddressRes = await MpService.getIpAddress()
      const myIpAddressStr = myIpAddressRes.data.split('=')[3]
      const myIpAdressOk = myIpAddressStr.slice(0, (myIpAddressStr.length - 3))
      // const ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
      // const myIpAddress = myIpAddressRes.data.match(ipRegex)[0]
      // console.log('---> myIpAddressRes', myIpAddressRes)
      // console.log('---> myIpAddress', myIpAddress)

      // PREBOOKING
      const prebookingObjInit = {
        professional_id: this.professionalId,
        startBook: this.startBook,
        endBook: this.endBook,
        duration: this.minutes,
        language: this.language
      }
      const prebookingRes = await PrebookingService.newPrebooking(idToken, prebookingObjInit)
      if (prebookingRes.status === 200) {
        this.prebookingId = prebookingRes.data._id
      } else {
        this.danger('Hubo un problema con el pago')
      }

      const obj = {}
      obj.AuthorId = this.user.mangopayuserid
      obj.DebitedFunds = {}
      obj.DebitedFunds.Currency = this.user.currency
      obj.DebitedFunds.Amount = this.price * 100
      // obj.SecureMode = 'NO_CHOICE'
      obj.SecureMode = 'DEFAULT'
      // obj.Requested3DSVersion = 'V2_1'
      obj.Requested3DSVersion = 'V1'
      obj.Culture = 'EN'
      obj.CardId = this.CardId
      // obj.SecureModeReturnURL = `https://emitus-panel.vercel.app/3dsecure/${this.prebookingId}`
      obj.SecureModeReturnURL = `${process.env.VUE_APP_DOMAIN}/3dsecure/${this.prebookingId}`
      obj.StatementDescriptor = 'emitus'
      obj.IpAddress = myIpAdressOk
      obj.BrowserInfo = {
        AcceptHeader: headers,
        JavaEnabled: navigator.javaEnabled(),
        Language: navigator.language,
        ColorDepth: screen.colorDepth,
        ScreenHeight: screen.availHeight,
        ScreenWidth: screen.availWidth,
        TimeZoneOffset: now.getTimezoneOffset(),
        UserAgent: navigator.userAgent,
        JavascriptEnabled: true
      }
      obj.CardType = 'CB_VISA_MASTERCARD'
      obj.PaymentType = 'CARD'
      obj.ExecutionType = 'DIRECT'

      try {
        const response = await MpService.preAuth(idToken, obj)
        return response
      } catch (error) {
        this.danger(error)
        return false
      }
    },

    // PreAuth with SAVED Card
    async PreAuthSavedCard () {
      if (this.radio) {
        const noOverlaps = await this.checkOverlaps()
        if (noOverlaps) {
          this.loader = true
          const idToken = await firebase.auth().currentUser.getIdToken(true)
          const response = await UserService.getCurrentUser(idToken)
          this.user = response.data
          const now = new Date()
          const request = new XMLHttpRequest()
          request.open('GET', document.location, false)
          request.send(null)
          const headers = request.getResponseHeader('content-type').toLowerCase()
          const myIpAddressRes = await MpService.getIpAddress()
          // const ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
          const myIpAddressStr = myIpAddressRes.data.split('=')[3]
          const myIpAdressOk = myIpAddressStr.slice(0, (myIpAddressStr.length - 3))
          // const myIpAddress = myIpAddressRes.data.match(ipRegex)[0]

          // PREBOOKING
          const prebookingObjInit = {
            professional_id: this.professionalId,
            startBook: this.startBook,
            endBook: this.endBook,
            duration: this.minutes,
            language: this.language
          }
          const prebookingRes = await PrebookingService.newPrebooking(idToken, prebookingObjInit)
          if (prebookingRes.status === 200) {
            this.prebookingId = prebookingRes.data._id
          } else {
            this.danger('Hubo un problema con el pago')
            this.loader = false
          }

          const obj = {}
          obj.AuthorId = this.user.mangopayuserid
          obj.DebitedFunds = {}
          obj.DebitedFunds.Currency = this.user.currency
          obj.DebitedFunds.Amount = this.price * 100
          // obj.SecureMode = 'NO_CHOICE'
          obj.SecureMode = 'DEFAULT'
          // obj.Requested3DSVersion = 'V2_1'
          obj.Requested3DSVersion = 'V1'
          obj.Culture = 'EN'
          obj.CardId = this.radio
          obj.SecureModeReturnURL = `${process.env.VUE_APP_DOMAIN}/3dsecure/${this.prebookingId}`
          // obj.SecureModeReturnURL = `https://emitus-panel.vercel.app/3dsecure/${this.prebookingId}`
          obj.StatementDescriptor = 'emitus'
          obj.IpAddress = myIpAdressOk
          obj.BrowserInfo = {
            AcceptHeader: headers,
            JavaEnabled: navigator.javaEnabled(),
            Language: navigator.language,
            ColorDepth: screen.colorDepth,
            ScreenHeight: screen.availHeight,
            ScreenWidth: screen.availWidth,
            TimeZoneOffset: now.getTimezoneOffset(),
            UserAgent: navigator.userAgent,
            JavascriptEnabled: true
          }
          obj.CardType = 'CB_VISA_MASTERCARD'
          obj.PaymentType = 'CARD'
          obj.ExecutionType = 'DIRECT'

          // console.log('---> 666333', obj)
          try {
            const response = await MpService.preAuth(idToken, obj)
            const SecureModeNeeded = response.data.SecureModeNeeded
            const SecureModeRedirectURL = response.data.SecureModeRedirectURL

            // A) IF SUCCEEDED
            if (response.data.Status === 'SUCCEEDED') { // It worked
              const obj = {
                price: (response.data.DebitedFunds.Amount / 100),
                currency: response.data.DebitedFunds.Currency,
                mppreauthid: response.data.Id
              }
              this.bookingRequest(obj)

            // B) IF FAILED
            } else if (response.data.Status === 'FAILED') { // It didn't work
              this.danger('Pago Fallido')
              this.loader = false

            // C) IF WAITING FOR BANK VALIDATION 3DS
            } else if (SecureModeNeeded) {
              const prebookingObj = {
                price: (response.data.DebitedFunds.Amount / 100),
                currency: response.data.DebitedFunds.Currency,
                mppreauthid: response.data.Id
              }
              const prebookingRes = await PrebookingService.updatePrebooking(idToken, this.prebookingId, prebookingObj)
              if (prebookingRes.status === 200) {
                window.open(SecureModeRedirectURL, '_self')
              } else {
                this.danger('Hubo un problema con el pago')
              }
            }
          } catch (error) {
            this.danger(error)
            this.loader = false
          }
        } else {
          this.danger('Por favor elige otra fecha')
          this.loader = false
        }
      } else {
        this.danger('Por favor elige método de pago')
        this.loader = false
      }
    },

    // PayIn With Balance
    async payInWithBalance () {
      // Check if the user has enough credits
      if (this.available < this.price) {
        this.danger('No tienes saldo suficiente')
      } else {
        const obj = {
          price: this.price,
          currency: this.currency,
          mppreauthid: 'credits' // nombre identificaivo?
        }
        this.bookingRequest(obj)
        this.loader = true
      }
    },

    // If Pre-Auth is ok, a booking request is made
    bookingRequest (obj) {
      this.notify.$emit('bookingRequest', obj)
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-danger'
      })
    },

    // Radio Off
    radioOff () {
      this.radio = 'gg'
    },

    // Delete Card
    async deleteCard (CardId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const response = await MpService.deleteCard(idToken, CardId)
      this.notify.$emit('refreshPayment')
    },

    // Check Overwrites
    async checkOverlaps () {
      const timezone = dayjs.tz.guess()

      // Este objeto es el que hay que crear
      const bookingObj = {
        professional_id: this.professionalId,
        startBook: this.startBook,
        endBook: this.endBook
      }
      // console.log(timezone)
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        return await BookingService.checkOverlaps(idToken, timezone, bookingObj) // TRUE --> NoOverlaps or FALSE
      } catch (error) {
        console.error('Conection Error', error.response.data.msg)
      }
    },
    clickFormNewCard () {
      this.radio = 'newCard'
      this.linkNewCard = false
      this.showNewCard = true
    },

    // get Alias of card
    async getCardAlias (cardId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)
      const myCardsResponse = await MpService.getMyCards(idToken)
      const myCardsData = myCardsResponse.data
      const myCards = myCardsData.filter(e => e.Id === cardId)
      const alias = myCards[0].Alias
      return alias
    }
  },

  beforeDestroy () {
    this.notify.$off('sendSummary')
  }
}
</script>

<style scoped>

.img-radius-intro {
  border-radius: 20px;
}

.expired-text {
  font-size: 12px;
}

#pay_ .field-body > .field:not(.is-narrow) {
  justify-content: space-between;
}
#pay_ .field-body {
  width: 95% !important;
}
#pay_ .btnDelete {
  /* right: 0px;
  position: absolute; */
  margin-left: 18px;
}

#pay_ #checkbox {
  box-shadow: none !important;
}

</style>

    // // PreAuth with SAVED Card
    // async PreAuthSavedCard () {
    //   if (this.radio) {
    //     const noOverlaps = await this.checkOverlaps()
    //     if (noOverlaps) {
    //       const alias = await this.getCardAlias(this.radio)
    //       const idToken = await firebase.auth().currentUser.getIdToken(true)
    //       const response = await UserService.getCurrentUser(idToken)
    //       this.user = response.data

    //       const obj = {}
    //       obj.AuthorId = this.user.mangopayuserid
    //       obj.DebitedFunds = {}
    //       obj.DebitedFunds.Currency = this.user.currency
    //       obj.DebitedFunds.Amount = this.price * 100
    //       obj.SecureMode = 'DEFAULT'
    //       obj.Culture = 'EN'
    //       obj.CardId = this.radio
    //       obj.SecureModeReturnURL = 'https://emitus-panel.vercel.app/panel/booking'

    //       // obj.Fees = {}
    //       // obj.Fees.Currency = this.user.currency
    //       // obj.Fees.Amount = 0
    //       // obj.CreditedWalletId = this.user.mangopaywallet.id
    //       obj.CreditedWalletId = this.mangopaywalletId
    //       obj.Tag = `Top up with Card ${alias}`
    //       obj.StatementDescriptor = 'emitus'
    //       obj.CardType = 'CB_VISA_MASTERCARD'
    //       obj.PaymentType = 'CARD'
    //       obj.ExecutionType = 'DIRECT'

    //       try {
    //         const response = await MpService.payIn(idToken, obj)
    //         console.log('Response from payInSavedCard', response)
    //         if (response.data.Status === 'SUCCEEDED') {
    //           const obj = {
    //             price: (response.data.DebitedFunds.Amount / 100),
    //             currency: response.data.DebitedFunds.Currency,
    //             mppayinid: response.data.Id
    //           }
    //           console.log('-------------- 123', obj)
    //           this.bookingRequest(obj)
    //         } else if (response.data.Status === 'FAILED') {
    //           alert('Payment Failed')
    //         }
    //       } catch (error) {
    //         console.log('Error from payInSavedCard -->', error)
    //         this.danger(error)
    //       }
    //     } else {
    //       this.danger('Por favor elige otra fecha')
    //     }
    //   } else {
    //     this.danger('Por favor elige método de pago')
    //   }
    // },
